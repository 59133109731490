import * as React from "react";
import { DestinationPropertiesCarousel as DestinationProperties } from "@shared-ui/retail-lodging-destination-properties-carousel";
import { observer } from "mobx-react";
import { DestinationPropertiesCarouselProps, DestinationPropertiesCarouselModuleResult } from "./typings";
import { withStores } from "src/stores";

const clickstreamConfig = {
  PAGE_NAME: "",
  APP_NAME: "",
};

/**
 * Wrapper for `@shared-ui/retail-lodging-destination-properties-carousel`
 * Powered by https://flexmanager.prod.expedia.biz/app/legacy/moduleStore/show/418
 */
export const DestinationPropertiesCarousel = withStores("flexModuleModelStore")(
  observer((props: DestinationPropertiesCarouselProps) => {
    const {
      templateComponent,
      flexModuleModelStore,
      context: { searchContext },
    } = props;

    /* istanbul ignore if */
    if (!templateComponent || !flexModuleModelStore) {
      return null;
    }

    const { metadata } = templateComponent;
    const { id } = metadata;
    const model = flexModuleModelStore.getModel(id) as DestinationPropertiesCarouselModuleResult | null;

    /* istanbul ignore if */
    if (!model) {
      return null;
    }

    const {
      regionId,
      regionName,
      adults,
      title = null,
      filterExternalIds,
      withCardBorder,
      dataType,
      cardHeadingSize,
      isRouteAndHoverDifferent,
    } = model;
    let selections;
    if (searchContext?.affinity && searchContext.affinity.bexApiFilters?.amenities) {
      selections = [{ id: "filters", value: searchContext.affinity.bexApiFilters.amenities }];
    }
    if (searchContext?.alternativeAccommodation?.lodgingUrlCode) {
      selections = [{ id: "filters", value: searchContext.alternativeAccommodation.lodgingUrlCode }];
    }
    if (filterExternalIds) {
      selections = [{ id: "filters", value: filterExternalIds.join(",") }];
    }

    const propertySearchCriteriaInput = {
      primary: {
        destination: {
          regionId,
          regionName,
        },
        rooms: [{ adults }],
      },
      ...(selections && selections.length > 0 ? { secondary: { selections } } : {}),
    };

    return (
      <section id={id} data-testid="destination-properties-carousel">
        <DestinationProperties
          inputs={{ propertySearchCriteriaInput, title }}
          clickstreamConfig={clickstreamConfig}
          withCardBorder={withCardBorder}
          dataType={dataType}
          cardHeadingSize={cardHeadingSize}
          isRouteAndHoverDifferent={isRouteAndHoverDifferent}
        />
      </section>
    );
  })
);

export default DestinationPropertiesCarousel;
